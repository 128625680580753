import React from "react"
import PropTypes from "prop-types"

const ToolBox = ({ formType, handleFormTypeChange, text, children }) => {
  return (
    <div className="tool-box">
      {text && (
        <div className="tool-header">
          <button className={formType === 'measures' ? 'selected' : 'regular'} onClick={() => handleFormTypeChange("measures")}>
            {text.measures}
          </button>
          <button className={formType !== 'measures' ? 'selected' : 'regular'} onClick={() => handleFormTypeChange("temperature")}>
            {text.temperatures}
          </button>
        </div>
      )}
      {children}
    </div>
  )
}
export default ToolBox

ToolBox.propTypes = {
  formType: PropTypes.string.isRequired,
  handleFormTypeChange: PropTypes.func.isRequired,
  text: PropTypes.object.isRequired,
}
