import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import bgImage from '../images/main-bg.jpg'
import "./layout.css"

const toCapitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const Layout = ({ pathname, title, description, children, copyText }) => {
  // helpers
  const isEn = pathname === '/en/';
  let title1 = ""
  let title2 = ""
  const titleArr = title.split(' ');
  if(!isEn) {
    title1 = `${titleArr[0]} ${titleArr[1]}`
    title2 = `${toCapitalize(titleArr[2])} ${toCapitalize(titleArr[3])}`
  } else {
    title1 = `${titleArr[0]} ${toCapitalize(titleArr[1])}`
    title2 = `${toCapitalize(titleArr[2])}`
  }
  // UI
  return (
    <div className="hero">
      <Header copyText={copyText}/>
      <img src={bgImage} className="image-bg" alt="kitchen tools" />
      <div className="content">
        <h1 className={isEn ? "title-bold" : "title-regular"}>{title1}</h1>
        <h1 className={isEn ? "title-regular" : "title-bold"}>{title2}</h1>
        <h3>{description}</h3>
      </div>
      <main>{children}</main>
    </div>
  )
}

Layout.propTypes = {
  pathname: PropTypes.string, 
  title: PropTypes.string, 
  description: PropTypes.string,
  copyText: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Layout
