import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import LangSelect from "./LangSelect"
import langIcon from '../images/language.png'

const Header = ({ copyText }) => {
  // state
  const [lang, setlang] = useState({ value: "en", label: "EN" })
  // side effects
  useEffect(() => {
    const currentPath = window.location.pathname
    if (currentPath === "/pt/") {
      setlang({ value: "pt", label: "PT" })
    } else if (currentPath === "/es/") {
      setlang({ value: "es", label: "ES" })
    } else {
      setlang({ value: "en", label: "EN" })
    }
  }, [])
  // handlers
  function handleChange(selected) {
    return navigate(`/${selected.value}/`)
  }
  // UI
  return (
    <header className="page-header">
      <div className="header-content">
        <div className="copy">
          <p>
            © {new Date().getFullYear()}, {copyText}
          </p>
          <a
            href="https://twitter.com/renan_costa_m"
            target="_blank"
            rel="noreferrer"
          >
            <strong>rcdev</strong>
          </a>
        </div>
        <div className="langs">
          <img src={langIcon} alt="language icon" />
          <LangSelect selected={lang} onChange={handleChange} />
        </div>
      </div>
    </header>
  )
}

export default Header
