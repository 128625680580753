import React from 'react'
import Select from 'react-select'
import PropTypes from "prop-types"

const options = [
  { value: "en", label: "EN" },
  { value: "es", label: "ES" },
  { value: "pt", label: "PT" },
];

const customStyles = {
  control: (styles) => ({ 
    ...styles, 
    width: '64px', 
    border: 'none', 
    backgroundColor: 'none', 
    fontSize: '14px',
    color: '#3E2B33',
    boxShadow: 'none',
    cursor: 'pointer'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontWeight: '700',
      backgroundColor: isFocused
        ? '#FFF6F2'
        : null,
      color: isSelected ? '#E57345' : '#3E2B33',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
    };
  },
  dropdownIndicator: styles => ({
    ...styles,
    color: "#3E2B33",
    ':hover' : {
      color: "#3E2B33",
    }
  }),
  indicatorSeparator: styles => ({ display: 'none'}),
  input: styles => ({ ...styles, color: '#3E2B33', border: 'none' }),
  placeholder: styles => ({ display: 'none' }),
  singleValue: styles => ({
    ...styles,
    fontWeight: '700',
    color: '#3E2B33'
  })
};

const LangSelect = ({ selected, onChange }) => {
  return (
    <Select 
      instanceId="lang-select"
      options={options} 
      styles={customStyles} 
      value={selected} 
      onChange={onChange} 
      isSearchable={false}
    />
  );
};

export default LangSelect

LangSelect.propTypes = {
  selected: PropTypes.object.isRequired, 
  onChange: PropTypes.func.isRequired, 
}